import styled from 'styled-components'
import { colors } from './assets/vars'

const AppStyle = styled.div`
    position: relative;
    isolation: isolate;
    height: 100vh;
    background-color: ${colors.salem};
    overflow: hidden;

    .background {
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transition-property: left, width, transform;
        transition-duration: .6s;
        transform: translate(-50%, -67%);
        width: 150vw;
        aspect-ratio: 1 / 1;
        background-color: ${colors.white};
        border-radius: 50%;

        &.is-welcome {
            left: 0;
            width: 1300px;
            transform: translate(-20%, -50%);
        }
    }
    
    .ball {
        position: absolute;
        z-index: -2;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        opacity: .2;
        background: linear-gradient(to top, ${colors.rangoon}, ${colors.pea});
        animation: floating 6s infinite ease-in-out;

        &--1 {
            top: 70px;
            right: -30px;
            width: 270px;
            animation-delay: 1s;
        }

        &--2 {
            left: 30%;
            bottom: 60px;
            width: 80px;
        }

        &--3 {
            bottom: -50px;
            left: 55%;
            width: 190px;
            animation-delay: 2s;
        }
    }
`

export default AppStyle;
