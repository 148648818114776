import { useState } from 'react'
import AppStyle from './style'
import Debug from './components/Debug'
import Footer from './components/Footer'
import Welcome from './components/Welcome'
import Team from './components/Team'
import Question from './components/Question'
import Finish from './components/Finish'
import { lsDecode } from './utils/localStorage'

const App = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const [step, setStep] = useState(lsDecode('qiStep') || 'welcome')
  const [question, setQuestion] = useState(lsDecode('qiQuestion', true) || 0)

  const displayStep = (s) => {
    switch(s) {
      case 'welcome':
        return <Welcome setStep={ setStep } />
      case 'team':
        return <Team setStep={ setStep } />
      case 'question':
        return <Question question={ question } setQuestion={ setQuestion } setStep={ setStep } />
      case 'finish':
        return <Finish />
      default:
        return
    }
  }

  return (
    <AppStyle>
      { urlParams.get('debug') !== null ? <Debug /> : '' }

      { displayStep(step) }

      <div className={`background is-${step}`}></div>
      <div className="ball ball--1"></div>
      <div className="ball ball--2"></div>
      <div className="ball ball--3"></div>

      <Footer />
    </AppStyle>
  )
}

export default App
