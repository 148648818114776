import styled from 'styled-components'
import { colors } from '../../assets/vars'

const FooterStyle = styled.footer`
    .logo-iserba {
        position: absolute;
        z-index: 1;
        bottom: 20px;
        left: 20px;

        svg {
            display: block;
            width: 165px;
            fill: ${colors.white};
        }
    }

    .logo-secu {
        position: absolute;
        z-index: 1;
        bottom: 20px;
        right: 20px;

        img {
            width: 120px;
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 200%;
            aspect-ratio: 1 / 1;
            transform: translate(-20%, -15%);
            border-radius: 50%;
            background-color: ${colors.white};
        }
    }
`;

export default FooterStyle;
