import { useEffect, useState } from 'react'
import ErrorStyle from './style'
import { formattedTimer } from '../../utils/timer'
import { lsEncode, lsDecode } from '../../utils/localStorage'
import { ReactComponent as Sad } from '../../assets/svgs/sad.svg'

const WAITING_TIME = 10

const Error = ({ explanation, nextQuestion }) => {
    const [timer, setTimer] = useState(lsDecode('qiTimerError', true) || WAITING_TIME)
    const [enabled, setEnabled] = useState(false)

    useEffect(() => {
        // Remove disable on button after {WAITING_TIME} seconds
        setTimeout(() => setEnabled(true), (lsDecode('qiTimerError', true) || WAITING_TIME) * 1000)
        
        // Loop add -1 every second
        const id = setInterval(() => setTimer((oldCount) => oldCount === 0 ? 0 : oldCount - 1), 1000)
        return () => clearInterval(id)
    }, [])

    // When timer change, add him to localStorage
    useEffect(() => lsEncode('qiTimerError', timer), [timer])

    return (
        <ErrorStyle>
            <div className="sad">
                <Sad />
                Mauvaise réponse
            </div>

            <div className="inner">
                <h3>Explication</h3>

                <p>{ explanation }</p>

                <button className={`btn ${ !enabled ? 'is-disabled' : '' }`} onClick={() => nextQuestion()}>
                    Suivant
                </button>

                <span className={timer === 0 ? 'is-disabled' : ''}>{ formattedTimer(timer) }</span>
            </div>
        </ErrorStyle>
    )
}

export default Error
