export const lsDecode = (key, number) => {
    return localStorage.getItem(key) ? number ? Number(atob(localStorage.getItem(key))) : atob(localStorage.getItem(key)) : null
}

export const lsEncode = (key, value) => {
    return localStorage.setItem(key, btoa(value))
}

export const lsRemove = (key) => {
    return localStorage.removeItem(key)
}

export const lsRemoveAll = () => {
    localStorage.removeItem('qiStep')
    localStorage.removeItem('qiTeam')
    localStorage.removeItem('qiTeamId')
    localStorage.removeItem('qiTimer')
    localStorage.removeItem('qiError')
    localStorage.removeItem('qiErrorsNb')
    localStorage.removeItem('qiTimerError')
    localStorage.removeItem('qiQuestion')
    window.location.reload()
}
