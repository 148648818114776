import styled from 'styled-components'
import { colors } from '../../assets/vars'

const WelcomeStyle = styled.section`
    display: flex;
    position: relative;
    top: 0;
    gap: 55px;
    padding: 6vw;
    transition: top .2s, opacity .2s;

    h1 {
        margin-bottom: 50px;
        font-size: 8rem;
        font-weight: 800;
        text-transform: uppercase;

        span {
            color: ${colors.ecstasy};
        }
    }

    svg {
        width: 100px;
        height: 100px;
    }

    &.is-disabled {
        top: 20px;
        opacity: 0;
    }
`;

export default WelcomeStyle;
