import styled from 'styled-components'
import { colors } from '../../assets/vars'

const TeamStyle = styled.section`
    display: flex;
    position: relative;
    top: 20px;
    z-index: 2;
    height: 90vh;
    flex-direction: column;
    opacity: 0;
    animation: fadeTopPop .2s .4s ease-in-out forwards;

    header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        margin: 0 30px;
        padding: 0 20px;
        border-bottom: 1px solid ${colors.rangoon_15};
        text-align: center;

        h1 {
            font-size: 3rem;
            font-weight: 800;
            text-transform: uppercase;

            span {
                color: ${colors.ecstasy};
            }
        }
    }

    form {
        display: flex;
        flex-direction: column;
        width: 430px;
        margin: auto;

        label {
            font-size: 2.1rem;
            margin-bottom: 10px;
        }

        input {
            height: 66px;
            margin-bottom: 30px;
            padding: 0 34px;
            transition: box-shadow .2s;
            border: 1px solid ${colors.dove};
            border-radius: 66px;
            outline: none;
            font-size: 2.1rem;
            text-transform: uppercase;
            
            &:focus,
            &:hover {
                box-shadow: 0 0 0 4px ${colors.rangoon_15};
            }
        }

        button {
            align-self: center;
        }
    }

    &.is-disabled {
        top: 0;
        opacity: 1;
        animation: fadeTopPopReverse .2s ease-in-out forwards;
    }
`;

export default TeamStyle;
