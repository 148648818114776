import styled from 'styled-components'
import { colors } from '../../assets/vars'

const ErrorStyle = styled.div`
    display: flex;
    position: fixed;
    overflow: hidden;
    top: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: ${colors.white_95};
    opacity: 0;
    animation: fadePop .2s ease-in-out forwards;

    .sad {
        position: relative;
        isolation: isolate;
        top: 20px;
        width: 420px;
        margin: 0 auto;
        padding-top: 35px;
        opacity: 0;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        text-transform: uppercase;
        animation: fadeTopPop .2s .2s ease-in-out forwards;

        svg {
            display: block;
            margin: 0 auto 12px;
            width: 40px;
            height: 40px;
        }

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            transform: translateY(-60%);
            background-color: ${colors.ecstasy};
        }
    }

    .inner {
        position: relative;
        top: 20px;
        margin: auto;
        max-width: 675px;
        width: 100%;
        opacity: 0;
        text-align: center;
        animation: fadeTopPop .2s .3s ease-in-out forwards;

        h3 {
            margin-bottom: 35px;
            font-size: 2rem;
            font-weight: 600;
            text-decoration: underline;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 75px;
            font-size: 1.8rem;
            line-height: 1.6;
        }

        span {
            display: block;
            margin-top: 5px;
            transition: opacity .2s .5s;
            opacity: .9;
            font-size: 1.4rem;

            &.is-disabled {
                opacity: 0;
            }
        }
    }
`;

export default ErrorStyle;
