import styled from 'styled-components'
import { colors } from '../../assets/vars'

const FinishStyle = styled.section`
    display: flex;
    position: relative;
    z-index: 2;
    top: 20px;
    height: 90vh;
    flex-direction: column;
    opacity: 0;
    animation: fadeTopPop .2s .2s ease-in-out forwards;

    header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 120px;
        margin: 0 30px;
        padding: 0 20px;
        border-bottom: 1px solid ${colors.rangoon_15};
        text-align: center;

        h1 {
            font-size: 3rem;
            font-weight: 800;
            text-transform: uppercase;

            span {
                color: ${colors.ecstasy};
            }
        }
    }

    .container {
        margin: 50px auto 0;
        max-width: 65%;
        width: 100%;
        text-align: center;

        h2 {
            max-width: 675px;
            margin: 0 auto 50px;
            font-size: 2rem;
            font-weight: 600;
            line-height: 1.6;
            
            span {
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        .timer {
            margin-bottom: 50px;
            font-size: 1.8rem;
            font-weight: 700;

            svg {
                display: block;
                width: 100px;
                margin: 0 auto 10px;
            }
        }
    }
`;

export default FinishStyle;
