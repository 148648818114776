import { createGlobalStyle } from 'styled-components';
import { colors } from './vars';
import { breakpoint } from './mixins';

const RulesStyles = createGlobalStyle`
    ::selection {
        background-color: ${colors.salem};
        color: ${colors.white};
    }

    body {
        color: ${colors.rangoon};
        font-family: 'Montserrat', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.25;

        ${breakpoint.s`
            font-size: 1.4rem;
        `}
    }

    // Buttons
    .btn {
        height: 64px;
        padding: 0 30px;
        transition: opacity .2s, background-color .2s, color .2s;
        border: 2px solid ${colors.ecstasy};
        border-radius: 66px;
        background-color: ${colors.ecstasy};
        color: ${colors.white};
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            background-color: transparent;
            color: ${colors.ecstasy};
        }
    }
`;

export default RulesStyles;
