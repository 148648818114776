import { useEffect, useState } from 'react'
import { getAllQuestions } from '../../api/question'
import { lsDecode, lsEncode } from '../../utils/localStorage'
import DebugStyle from './style'
import { lsRemoveAll } from '../../utils/localStorage'


const Debug = () =>  {
    const [question, setQuestion] = useState('')
    const [questions, setQuestions] = useState('')

    useEffect(() => {
        getAllQuestions().then(res => setQuestions(res.data.data))
    }, [])

    return (
        <DebugStyle>
            <form onSubmit={(e) => {
                e.preventDefault()
                if ( ! lsDecode('qiTeamId', true) ) {
                    alert ("Créez une team avant d'accéder à une question")
                } else if( question < 1 || question - 1 > questions.length) {
                    alert ("Cette question n'existe pas")
                } else {
                    lsEncode('qiQuestion', question - 1)
                    window.location.reload()
                }
            }}>
                <label htmlFor="question">Allez à la question :</label>
                <input id="question" type="text" value={ question } onChange={(e) => setQuestion(e.target.value)} />
            </form>

            <button className="debug" onClick={() => lsRemoveAll()}>
                Recommencer
            </button>
        </DebugStyle>
    )
}

export default Debug
