import FooterStyle from './style'
import { ReactComponent as LogoIserba } from '../../assets/svgs/logo-iserba.svg'
import LogoSecu from '../../assets/images/logo-secu.png'

const Footer = () => (
    <FooterStyle>
        <figure className="logo-iserba">
            <LogoIserba />
        </figure>

        <figure className="logo-secu">
            <img src={ LogoSecu } alt="" />
        </figure>
    </FooterStyle>
)

export default Footer
