import { createGlobalStyle } from "styled-components"

const HelpersStyles = createGlobalStyle`
    // Image cover
    .bg-cover {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .img-cover {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // Text alignments
    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }
`;

export default HelpersStyles;
