import styled from 'styled-components'
import { colors } from '../../assets/vars'

const DebugStyle = styled.div`
    display: flex;
    position: absolute;
    z-index: 1000;
    top: 140px;
    left: 20px;
    gap: 10px;
    flex-direction: column;
    padding: 5px 10px;
    border: 2px dashed red;
    background-color: ${colors.white};

    button {
        display: block;
        padding: 5px 10px;
        background-color: ${colors.rangoon};
        color: ${colors.white};

        &:hover {
            opacity: .9;
        }
    }

    form {
        display: flex;
        gap: 5px;
        align-items: center;

        label {
            font-size: 1.4rem;
        }

        input {
            width: 30px;
            padding: 5px 3px;
            border: 1px solid ${colors.rangoon};
            font-size: 1.4rem;
        }
    }
`;

export default DebugStyle;
