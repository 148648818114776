import styled from 'styled-components'
import { colors } from '../../assets/vars'

const QuestionStyle = styled.section`
    display: flex;
    position: relative;
    z-index: 2;
    top: 20px;
    height: 90vh;
    flex-direction: column;
    opacity: 0;
    animation: fadeTopPop .2s .2s ease-in-out forwards;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 120px;
        padding: 24px 0;
        margin: 0 30px;
        border-bottom: 1px solid ${colors.rangoon_15};
        text-align: center;

        h1 {
            font-size: 3rem;
            font-weight: 800;
            text-transform: uppercase;

            span {
                color: ${colors.ecstasy};
            }
        }

        .number {
            flex: 0 0 150px;
            font-size: 1.9rem;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;

            span {
                font-size: 2.7rem;
                font-weight: 700;
            }
        }

        .timer {
            flex: 0 0 150px;
            text-align: center;
            font-size: 2rem;
            font-weight: 700;

            svg {
                display: block;
                width: 30px;
                margin: 0 auto 10px;
                fill: ${colors.rangoon};
            }
        }
    }

    .container {
        margin: 50px auto 0;
        max-width: 65%;
        width: 100%;

        h2 {
            max-width: 675px;
            margin: 0 auto 50px;
            font-size: 2rem;
            font-weight: 600;
            text-align: center;
            line-height: 1.6;
        }

        .question {
            display: flex;
            gap: 40px;

            video,
            figure {
                overflow: hidden;
                flex: 1;
            }

            video {
                aspect-ratio: 16 / 9;
            }

            figure {
                border-radius: 17px;
            }

            ul {
                display: flex;
                flex: 1;
                gap: 20px;
                flex-direction: column;
                justify-content: space-between;

                button {
                    width: 100%;
                    height: 55px;
                    padding: 0 30px;
                    border-radius: 55px;
                    transition: background-color .2s, color .2s, box-shadow .2s;
                    border: 1px solid ${colors.dove};
                    text-align: left;
                    background-color: ${colors.white};

                    &:hover {
                        box-shadow: 0 0 0 4px ${colors.rangoon_15};
                    }

                    &.is-disable {
                        pointer-events: none;
                    }

                    &.is-active {
                        background-color: ${colors.salem};
                        color: ${colors.white};
                        font-weight: 600;

                        &.is-error {
                            background-color: ${colors.ecstasy};
                        }
                    }
                }
            }
        }
    }

    &.is-disabled {
        top: 0;
        opacity: 1;
        animation: fadeTopPopReverse .2s ease-in-out forwards;
    }
`;

export default QuestionStyle;
