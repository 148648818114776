import { useState } from 'react'
import TeamStyle from './style'
import { createTeam } from '../../api/team'
import { lsEncode } from '../../utils/localStorage'

const Team = ({ setStep }) => {
    const [disabled, setDisaled] = useState(false)
    const [team, setTeam] = useState('')

    return (
        <TeamStyle className={disabled ? 'is-disabled' : ''}>
            <header>
                <h1>Le quiz de la <span>sécurité</span></h1>
            </header>

            <form onSubmit={(e) => {
                e.preventDefault()
                
                setDisaled(true)
                lsEncode('qiTeam', team)
                createTeam({name: team}).then(res => lsEncode('qiTeamId', res.data.data.id))

                setTimeout(() => {
                    setStep('question')
                    lsEncode('qiStep', 'question')
                }, 200)
            }}>
                <label htmlFor="team">
                    Entrez votre nom d'équipe :
                </label>

                <input id="team" type="text" value={ team } onChange={(el) => setTeam(el.target.value)} />

                <button className={`btn ${team.length < 1 ? 'is-disabled' : ''}`} type="submit">
                    Commencer le quiz
                </button>
            </form>
        </TeamStyle>
    )
}

export default Team
