import { rgba } from "polished"

export const colors = {
  black:      '#000',
  white:      '#fff',
  white_95:   rgba('#fff', .95),
  ecstasy:    '#fb7210',
  ecstasy_15: rgba('#fb7210', .3),
  salem:      '#0f9c39',
  salem_15:   rgba('#0f9c39', .3),
  pea:        '#1a5e2b',
  rangoon:    '#151610',
  dove:       '#707070',
  rangoon_15: rgba('#151610', .15),
};
