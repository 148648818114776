import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Global styled
import NormalizeStyles from "./assets/normalize"
import HelpersStyles from "./assets/helpers"
import RulesStyles from "./assets/rules"
import AnimsStyles from "./assets/anims"

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <>
    {/* Gfonts */}
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap" rel="stylesheet" /> 

    {/* Common styles */}
    <NormalizeStyles />
    <HelpersStyles />
    <RulesStyles />
    <AnimsStyles />

    {/* App */}
    <App />
  </>
)
