import FinishStyle from './style'
import { ReactComponent as TimerBig } from '../../assets/svgs/timer-big.svg'
import { formattedTimer } from '../../utils/timer'
import { lsDecode, lsRemoveAll } from '../../utils/localStorage'

const Finish = () => (
    <FinishStyle>
        <header>
            <h1>Le quiz de la <span>sécurité</span></h1>
        </header>

        <div className="container">
            <h2><span>{ lsDecode('qiTeam') }</span>, votre temps :</h2>

            <div className="timer">
                <TimerBig />
                { formattedTimer(lsDecode('qiTimer', true)) }
            </div>

            <button className="btn" onClick={() => lsRemoveAll()}>
                Recommencer
            </button>
        </div>
    </FinishStyle>
)

export default Finish
