import { useEffect, useState } from 'react'
import QuestionStyle from './style'
import Error from '../Error'
import { updateTeam } from '../../api/team'
import { getAllQuestions } from '../../api/question'
import { lsEncode, lsDecode, lsRemove } from '../../utils/localStorage'
import { formattedTimer } from '../../utils/timer'
import { ReactComponent as Timer } from '../../assets/svgs/timer.svg'

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

const Question = ({ question, setQuestion, setStep }) => {
    const [disabled, setDisaled] = useState(false)
    const [questions, setQuestions] = useState([])
    const [timer, setTimer] = useState(lsDecode('qiTimer', true) || 0)
    const [error, setError] = useState(lsDecode('qiError', true) ? true : false)
    const [errorNb, setErrorsNb] = useState(lsDecode('qiErrorsNb', true) || 0)
    const [disableClick, setDisableClick] = useState(lsDecode('qiError', true) ? true : false)
    const [selectedAnswer, setSelectedAnswer] = useState()

    // Loop add +1 every second
    useEffect(() => {
        const id = setInterval(() => setTimer((oldCount) => oldCount + 1), 1000)

        getAllQuestions().then(res => setQuestions(res.data.data))

        return () => clearInterval(id)
    }, [])

    // When timer change, add him to localStorage
    useEffect(() => lsEncode('qiTimer', timer), [timer])

    // Triggered when answer is clicked
    const answerClicked = (answer) => {
        setDisableClick(true)
        setSelectedAnswer(answer)

        updateTeam(lsDecode('qiTeamId'), {
            totalQuestions: String(question + 1)
        })

        setTimeout(() => {
            if ( answer === questions?.[question].attributes.good ) {
                nextQuestion()
            } else {
                setError(true)
                lsEncode('qiError', 1)
                
                setErrorsNb(errorNb + 1)
                lsEncode('qiErrorsNb', errorNb + 1)
                updateTeam(lsDecode('qiTeamId'), {
                    errors: String(errorNb + 1)
                })
            }
        }, 800)
    }

    const nextQuestion = () => {
        setError(false)
        lsEncode('qiError', 0)
        lsRemove('qiTimerError')

        if ( question + 1 < questions.length ) {
            setDisableClick(false)
            setSelectedAnswer()
            setQuestion( question + 1 )
            lsEncode('qiQuestion', question + 1)
            updateTeam(lsDecode('qiTeamId'), {
                timer: formattedTimer(timer)
            })
        } else {
            setDisaled(true)

            setTimeout(() => {
                setStep('finish')
                lsEncode('qiStep', 'finish')
                updateTeam(lsDecode('qiTeamId'), {
                    timer: formattedTimer(timer),
                    finish: true
                })
            }, 200)
        }
    }

    if ( !questions.length ) {
        return 
    }

    return (
        <QuestionStyle className={disabled ? 'is-disabled' : ''}>
            <header>
                <div className="number">
                    Question<br />
                    <span>{ question + 1 }/{ questions.length }</span>
                </div>

                <h1>Le quiz de la <span>sécurité</span></h1>

                <div className="timer">
                    <Timer />
                    { formattedTimer(timer) }
                </div>
            </header>

            <div className="container">
                <h2>{ questions[question].attributes.title }</h2>

                <div className="question">
                    { questions[question].attributes.media.data ? questions[question].attributes.media.data.attributes.mime.includes('video') ? (
                        <video controls>
                            <source src={questions[question].attributes.media.data.attributes.url.includes('http') ? questions[question].attributes.media.data.attributes.url : `https://${questions[question].attributes.media.data.attributes.url}`} type={ questions[question].attributes.media.data.attributes.mime } />
                        </video>
                    ) : (
                        <figure>
                            <img className="img-cover" src={ questions[question].attributes.media.data.attributes.url } alt="" />
                        </figure>
                    ) : '' }

                    <ul>
                        { questions[question].attributes.answers.map((item, key) => (
                            <li key={ key }>
                                <button className={`${disableClick ? 'is-disable' : ''} ${selectedAnswer === key ? 'is-active' : ''} ${selectedAnswer !== questions[question].attributes.good ? 'is-error' : ''}`} onClick={() => answerClicked(key)}>
                                    { alphabet[key] }. { item.title }
                                </button>
                            </li>
                        )) }
                    </ul>
                </div>
            </div>

            { error ? (
                <Error explanation={ questions[question].attributes.explanation } nextQuestion={ nextQuestion } />
            ) : '' }
        </QuestionStyle>
    )
}

export default Question
