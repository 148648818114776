import { useState } from 'react'
import WelcomeStyle from './style'
import { ReactComponent as TimerBig } from '../../assets/svgs/timer-big.svg'
import { lsEncode } from '../../utils/localStorage'

const Welcome = ({ setStep }) => {
    const [disabled, setDisaled] = useState(false)

    return (
        <WelcomeStyle className={disabled ? 'is-disabled' : ''}>
            <TimerBig />

            <div>
                <h1>Le quiz<br />de la<br /><span>sécurité</span></h1>

                <button className="btn" onClick={() => {
                    setDisaled(true)

                    setTimeout(() => {
                        setStep('team')
                        lsEncode('qiStep', 'team')
                    }, 200)
                }}>
                    Commencer
                </button>
            </div>
        </WelcomeStyle>
    )
}

export default Welcome
