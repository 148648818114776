import { css } from 'styled-components'

const br = {
	xs: '400px',
	s: '768px',
	m: '1023px',
	l: '1599px'
  }

export const breakpoint = Object.keys(br).reduce((accumulator, label) => {
	accumulator[label] = (...args) => css`
	  @media screen and (max-width: ${br[label]}) {
		${css(...args)};
	  }
	`
	return accumulator
  }, {})
