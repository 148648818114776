import Axios from 'axios'

/**
 * Create Axios instance with api baseURL from env vars
 */
const axios = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    crossdomain: true
});

export default axios;
