import { createGlobalStyle } from 'styled-components';

const AnimsStyles = createGlobalStyle`
    @keyframes floating {
        0%,
        100% {
            transform: translateY(-15px);
        }

        50% {
            transform: translateY(15px);
        }
    }

    @keyframes fadePop {
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeTopPop {
        100% {
            top: 0;
            opacity: 1;
        }
    }

    @keyframes fadeTopPopReverse {
        100% {
            top: 20px;
            opacity: 0;
        }
    }
`;

export default AnimsStyles;
